<template>
	<div>
		<Header ref="Header"/>
		<img :src="menuPic" class="Big-title"/>
		<div class="container">
			<div class="personal-list row">
				<div class="personal-list-left">
					<div class="index-sort-tiltle personal-sort-tiltle">个人中心</div>
					<div class="index-sort-list">
						<div v-for="(item,index) in nav">
							<div @click="sortSwitch(index)" :class="sortIndex==index?'index-sort-item2':'index-sort-item'">
								{{item.name}}
							</div>
							<img class="index-sort-boder" v-if="sortIndex==index" src="../../public/images/boder_01.png" />
						</div>
					</div>
				</div>
				<div class="personal-list-right">
					<div class="personal-right-message">
						<div class="personal-message-list">
							<div v-for="(item,index) in message" :key="index">
								<div class="personal-message-item">
									<div class="row" :class="item.status?'personal-message-line':'personal-message-line1'">
										<div class="personal-message-title">{{item.title}}</div>
										<div class="personal-message-time">{{item.createTime}}</div>
									</div>
									<div class="personal-message-text">{{item.content}}</div>
								</div>
							</div>
							<div v-if="message.length==0" class="personal-message-tips">暂无消息~</div>
							<el-pagination
								style="margin-top: 50px;"
								class="bar-switch"
								background
								layout="prev, pager, next, jumper, total"
								:page-size="postData.pageSize"
								:total="Total"
								@current-change="choosePage">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import  Header from '@/components/header.vue';
	import { mapState } from 'vuex';
	export default {
		components:{Header},
		data() {
			return {
				Total:0,
				menuPic:'',
				sortIndex:5,
				postData:{
					pageNum:1,
					pageSize:10,
					orderByColumn:'createTime',
					isAsc:'desc'
				},
				nav:[
					{name:'个人信息',href:'personal'},
					{name:'发布需求',href:'publish'},
					{name:'我的订单',href:'myOrder'},
					{name:'我的发布',href:'myPublish'},
					{name:'我的收藏',href:'myCollect'},
					{name:'我的消息',href:'myNews'},
					{name:'意见反馈',href:'feedback'},
				],
				message:[],
			}
		},
		computed:{
		...mapState(['userInfo']),
		},
		mounted() {
			this.$refs.Header.setIndex(-1);
			// 获取菜单图片
			this.$api.getMenuPic('发布需求').then(res=>{
				console.log(res,'菜单图片');
				this.menuPic=res.data[0].picture;
			})
			// 获取消息列表
			this.getNewsList();
		},
		methods: {
			sortSwitch(index){
				this.sortIndex=index;
				this.$router.push({path:this.nav[index].href});
			},
			// 获取消息列表
			getNewsList(){
				this.$api.myNews(this.postData).then(res=>{
					console.log(res,'消息列表');
					this.message=res.rows;
					this.Total=parseInt(res.total);
				})
			},
			// 选择分页
			choosePage(num){
				this.postData.pageNum=num;
				this.getNewsList();
			},
		},
	};
</script>
<style scoped>
</style>